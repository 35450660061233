.lds-facebook {
    display: inline-block;
    position: relative;
    width: 10px;
    height: 10px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 2px;
    width: 5px;
    background: #6366f1;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 20px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 32px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }

/* test */
    .three-body {
      --uib-size: 35px;
      --uib-speed: 0.8s;
      --uib-color: #5D3FD3;
      position: relative;
      display: inline-block;
      height: var(--uib-size);
      width: var(--uib-size);
      animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
    }

    .three-body__dot {
      position: absolute;
      height: 100%;
      width: 30%;
    }

    .three-body__dot:after {
      content: '';
      position: absolute;
      height: 0%;
      width: 100%;
      padding-bottom: 100%;
      background-color: var(--uib-color);
      border-radius: 50%;
    }

    .three-body__dot:nth-child(1) {
      bottom: 5%;
      left: 0;
      transform: rotate(60deg);
      transform-origin: 50% 85%;
    }

    .three-body__dot:nth-child(1)::after {
      bottom: 0;
      left: 0;
      animation: wobble1 var(--uib-speed) infinite ease-in-out;
      animation-delay: calc(var(--uib-speed) * -0.3);
    }

    .three-body__dot:nth-child(2) {
      bottom: 5%;
      right: 0;
      transform: rotate(-60deg);
      transform-origin: 50% 85%;
    }

    .three-body__dot:nth-child(2)::after {
      bottom: 0;
      left: 0;
      animation: wobble1 var(--uib-speed) infinite calc(var(--uib-speed) * -0.15) ease-in-out;
    }

    .three-body__dot:nth-child(3) {
      bottom: -5%;
      left: 0;
      transform: translateX(116.666%);
    }

    .three-body__dot:nth-child(3)::after {
      top: 0;
      left: 0;
      animation: wobble2 var(--uib-speed) infinite ease-in-out;
    }

    @keyframes spin78236 {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes wobble1 {

      0%,
      100% {
        transform: translateY(0%) scale(1);
        opacity: 1;
      }

      50% {
        transform: translateY(-66%) scale(0.65);
        opacity: 0.8;
      }
    }

    @keyframes wobble2 {

      0%,
      100% {
        transform: translateY(0%) scale(1);
        opacity: 1;
      }

      50% {
        transform: translateY(66%) scale(0.65);
        opacity: 0.8;
      }
    }
